.projectpage-featured-video {
    width: 80%;
    height: 600px;
    border-radius: 10px;
}

.projectpage-featured-video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 25px 0px;
}

.projectpage-backlink-container {
    margin-top: 25px;
}

.projectpage-backlink {
    color: var(--gray);
}

.projectpage-details-container {
    text-align: left;
    margin-bottom: 25px;
}

.gallery-image {
    border-radius: 5px;
}

.gallery-double-landscape {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

}

.gallery-double-landscape-image {
    width: 800px;
    height: auto;

}

.gallery-single-vertical-image {
    height: 920px;
    width: auto;
}

.gallery-default-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;

}

.gallery-default-row-image {
    width: 700px;
    height: auto;
}

.projectpage-gallery {
    margin: 50px 0px;
}

.cs1-gallery {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.cs2-gallery {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.cs2-gallery-firstrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.pg2-gallery {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.wsb1-gallery {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.wsb2-gallery {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

@media screen and (max-width: 1400px) {
    .projectpage-featured-video {
        width: 100%;
        height: 600px;
    }

    .gallery-double-landscape-image {
        width: 100%;
        height: auto;
    }

    .gallery-single-vertical-image {
        width: 100%;
        height: auto;
    }

    .gallery-double-landscape {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

    .gallery-default-row {
        flex-wrap: wrap;
    }

    .cs1-gallery {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

    .cs2-gallery-firstrow {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

}

@media screen and (max-width: 800px) {
    .projectpage-featured-video {
        width: 100%;
        height: 400px;
    }

    .gallery-double-landscape-image {
        width: 100%;
        height: auto;
    }

    .gallery-single-vertical-image {
        width: 100%;
        height: auto;
    }

    .gallery-default-row-image {
        width: 100%;
        height: auto;
    }

    .gallery-double-landscape {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

    .cs1-gallery {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

    .cs2-gallery-firstrow {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

}

@media screen and (max-width: 560px) {
    .projectpage-featured-video {
        width: 90%;
        height: 300px;
    }

    .gallery-default-row-image {
        width: 90%;
        height: auto;
    }

    .projectpage-details-container {
        margin: 25px 25px;
    }

    .cs1-gallery {
        margin: 25px 25px;
    }

    .cs2-gallery-firstrow {
        margin: 0px 25px;
    }

}

@media screen and (max-width: 395px) {
    .projectpage-featured-video {
        width: 90%;
        height: 200px;
    }

}