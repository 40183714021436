.services-featured-video-container {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.services-featured-video {
    width: 890px;
    height: 500px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.services-videos-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-bottom: 50px;
    gap: 50px;
}

.services-video {
    width: 600px;
    height: 300px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 990px) {
    .services-featured-video {
        width: 600px;
        height: 320px;
    }
}

@media screen and (max-width: 625px) {
    .services-featured-video {
        width: 450px;
        height: 250px;
    }

    .services-video {
        width: 450px;
        height: 250px;
    }
}

@media screen and (max-width: 395px) {
    .services-featured-video {
        width: 350px;
        height: 200px;
    }

    .services-video {
        width: 350px;
        height: 200px;
    }
}