.projectspage-title {
    font-size: 48px;
    font-weight: 500;
    color: var(--spaceblack);
    text-align: center;
    margin: 50px 0px !important;
}

.projectspage-content {
    margin-bottom: 100px;
}