.service-section {
    height: 90vh;
    width: 100%;
    display: flex;
    text-align: center;
}

.services-container {
    position: relative;
    text-align: left;
    display: grid;
    grid-template-columns: 70% 30%;
    background-size: cover;
    background-position: center;
    z-index: 1;
    border-radius: 5px;
}

.service-focus {
    position: relative;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 80px;
    color: var(--white);
    align-items: center;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, border 0.3s ease-in-out;
}

.services-container::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(50, 50, 47, 0.7);
    z-index: -1;
    border-radius: 5px;
}

.service-list {
    position: relative;
    z-index: 2;
}

.container {
    --bs-gutter-x: 0 !important;
}

.service-focus-title {
    font-size: 40px;
    font-weight: 600;
    padding-bottom: 20px;
    border-bottom: solid 2px rgba(255, 255, 255, 0.538);
}

.service-focus-desc {
    font-size: 24px;
    font-weight: 300;
    margin-top: 50px;
}

@media screen and (max-width: 990px) {
    .services-container {
        display: flex;
        flex-direction: column-reverse;
    }

    .service-list {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .service-focus {
        padding-left: 20px;
        padding-right: 20px;
    }

    .service-focus-title {
        font-size: 30px;
    }

    .service-focus-desc {
        font-size: 20px;
    }

}

@media screen and (max-width: 450px) {
    .services-container {
        grid-template-columns: 100%;
    }

    .service-list {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .service-focus {
        margin: 20px 10px;

    }

    .service-focus-title {
        font-size: 35px !important;
    }

    .service-focus-desc {
        font-size: 18px;
        margin-top: 5px;
    }
}