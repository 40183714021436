.projects-section {
    margin-top: 100px;
    width: 100%;
    display: flex;
    text-align: center;
    margin-bottom: 100px;
}

.projects-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}


@media screen and (max-width: 990px) {
    .projects-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
}

