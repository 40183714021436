.footer {
    background-color: #101010;
    padding-top: 50px;
    border-top: 2px solid var(--gray);
}

.footer-content {
    display: flex;
    flex-wrap: wrap;
    gap: 100px;
    color: var(--gray);
    justify-content: center;
    align-items: flex-start;
}

.footer-contact-content {
    margin-top: 10px;
    color: var(--white);
    font-size: 16px !important;
}

.footer-contact-item {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.footer-contact-item a {
    margin-top: 0px;
    color: var(--white);
}

.footer-contact-icon {
    font-size: 20px;
    margin-top: 5px;
    color: var(--orange);
}

.footer-links {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    gap: 15px;
    column-count: 2; 
    column-gap: 50px; 
    column-fill: auto; 
}

.footer-credits {
    color: var(--white);
    text-align: center;
    margin-top: 50px;
}

.footer-credits p {
    font-size: 16px;
    margin-bottom: 0px;
    color: var(--gray);
}

.footer-links a {
    color: var(--white);
    text-decoration: none;
    font-size: 16px;
}

.footer-category-title {
    font-size: 24px;
    font-weight: 500;
    margin: 0;
    line-height: 1;
    color: var(--white);
}

a:hover {
    color: var(--orange);
}

@media screen and (max-width: 450px) {
    .footer-content {
        display: flex;
        flex-direction: column;
        gap: 50px;
        color: var(--gray);
        justify-content: center;
        align-items: left;
        padding-left: 50px;
    }
}