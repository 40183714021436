.contact-tile-header {
    display: flex;
    gap: 10px;
}

.contact-tile-icon {
    font-size: 50px;
    color: var(--orange);
}

.contact-tile-title {
    margin-top: 20px;
    margin-bottom: 0px !important;
    font-size: 36px !important;
    font-weight: bold !important;
}

.contact-tile-subtitle {
    margin-top: 0px !important;
    font-size: 24px !important;
    font-weight: normal !important;
}

.contact-tile {
    position: relative;
}

