.hero-section {
    background-image: url('../../../../images/p1.jpg');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 103vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -100px;
}

.hero-content {
    justify-content: left;
    align-items: left;
    height: 100%;
    width: 50%;
}

.hero-title {
    font-size: 96px;
    font-weight: bold;
    color: var(--white);
    margin: 0;
    line-height: 1.2;
}

.hero-btn {
    padding-top: 50px;
}

@media screen and (max-width: 995px) {
    .hero-title {
        font-size: 70px;
        text-align: center;
    }

    .hero-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 450px) {
    .hero-title {
        font-size: 60px;
        text-align: center;
    }

    .hero-section {
        background-position: center;
        background-size: cover;
    }

}