.about-section {
    height: 90vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
}

.about-image {
    height: 500px;
    width: 650px;
    object-fit: cover;
    object-position: -120px center; 
}

@media screen and (max-width: 1200px) {
    .about-image {
        height: 400px;
        width: 550px;
    }
}

@media screen and (max-width: 995px) {
    
    .about-content {
        flex-direction: column;
        gap: 20px;
        margin-top: 100px;
        margin-left: 50px;
        margin-right: 50px;
    }

    .about-section {
        height: 100%;
        margin-bottom: 100px;
    }


}

@media screen and (max-width: 450px) {
    
    .about-content {
        margin-top: 0px;
        gap: 0px;
    }
    
    .about-image {
        height: 300px;
        width: 390px;
        object-fit: contain;
        object-position: 0px center; 
    }

}