.pageheader {
    position: relative;
    height: 35vh;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: -100px;
    color: var(--white);
    background-position: center center;
    background-size: 100%;
    padding-top: 100px;
}

.pageheader::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  
  .pageheader-content {
    position: relative;
    z-index: 2;
  }

.pageheader-title {
    font-size: 64px !important;
    font-weight: bold;
}

@media screen and (max-width: 625px) {
    .pageheader-title {
        font-size: 36px !important;
        text-align: center;
    }

    .pageheader{
      background-size: cover;
    }
}

@media screen and (max-width: 395px) {

}