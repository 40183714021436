.brand-container {
    display: flex;
    align-items: center;
}

.brand-logo {
    margin-right: 10px;
}

.brand-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 20px;
    text-align: center;
}

.nav-title {
    font-size: 22px;
    font-weight: 500;
    margin: 0;
    line-height: 1;
}

.nav-subtitle {
    font-size: 12px;
    margin: 0;
}

.navbar {
    transition: background-color 0.5s ease;
    background-color: var(--spaceblack);
}

.link {
    text-decoration: none;
    color: white;

}

.nav-dropdown .link {
    color: black;
}

.nav-link {
    color: white !important;
}

.dropdown-menu {
    background-color: var(--white);
    color: var(--spaceblack);
    border: none;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.dropdown-menu a:hover {
    background-color: var(--white);
    color: var(--orange);
}