@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.project-item {
    flex-grow: 1;
    width: 40%;
    text-align: left;
    background-position: center;
    background-size: 150%;
    border-radius: 5px;
    transition: background-size 0.2s ease-in-out, letter-spacing 0.2s ease-in-out;
    position: relative;
    overflow: hidden;
}

.project-item:hover {
    background-size: 170%; 
    cursor: pointer;
    letter-spacing: 0.5px;
}

.project-item:hover::after {
    content: "Learn More →";
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: var(--white);
    font-size: 1.2em;
    animation: slideInFromLeft 0.2s forwards;
}

.project-item-container {
    height: 35vh;
    padding: 20px;
    color: var(--white);
}

@media screen and (max-width: 990px) {
    .project-item {
        width: 600px !important;
    }
}

@media screen and (max-width: 450px) {
    .project-item {
        width: 370px !important;
    }
}

