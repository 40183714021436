@import "components/pages/home/HeroSection/HeroSection.css";
@import "components/pages/home/AboutSection/AboutSection.css";
@import "components/pages/home/ServiceSection/ServiceSection.css";
@import "components/pages/home/ProjectsSection/ProjectsSection.css";
@import "components/pages/home/ContactSection/ContactSection.css";
@import "components/pages/contact/Contact.css";
@import "components/pages/Projects/Projects.css";
@import "components/pages/Projects/FacilitiesPage/FacilitiesPage.css";
@import "components/pages/Services/ServicePages.css";
@import "components/pages/Services/EngineeringandDraftingPage/EngineeringandDraftingPage.css";
@import "components/pages/Services/ConstructionDesignPage/ConstructionDesignPage.css";
@import "components/pages/Services/3DAnimationPage/3DAnimationPage.css";
@import "components/pages/Services/AsBuiltPage/AsBuiltPage.css";
@import "components/pages/Services/3DScanningPage/3DScanningPage.css";
@import "components/pages/Projects/ProjectPages/ProjectPages.css";
@import "components/common/ConstructionMessage/ConstructionMessage.css";

@import "components/common/navbar/Navigation.css";
@import "components/common/BigButton/BigButton.css";
@import "components/common/ServiceItem/ServiceItem.css";
@import "components/common/ProjectItem/ProjectItem.css";
@import "components/common/Footer/Footer.css";
@import "components/common/PageHeader/PageHeader.css";
@import "components/common/ContactTile/ContactTile.css";
@import "components/common/SocialTile/SocialTile.css";

:root {
  --orange: #F7931E;
  --gray: #4D4D4D;
  --white: #FFF;
  --black: #000000;
  --spaceblack: #1E1E1E;
}

* {
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

body,
html {
  margin: 0;
  min-height: 100vh;
  
}

h1 {
  font-size: 48px !important;
  font-weight: semi-bold !important;
  margin-bottom: 30px !important
}

p {
  font-size: 20px;
}

a {
  text-decoration: none;
  color: var(--black);
}

.text-orange {
  color: var(--orange);
}

.text-white {
  color: var(--white);
}

.text-black {
  color: var(--black);
}

.text-spaceblack {
  color: var(--spaceblack)
}

.text-gray {
  color: var(--gray);
}

.page-title {
  font-size: 48px;
  font-weight: bold;
  color: var(--spaceblack);
  margin-top: 50px;
  line-height: 1.2;
}