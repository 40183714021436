.asbuiltpage-content-container {
    margin-top: 50px;
}

@media screen and (max-width: 990px) {

}

@media screen and (max-width: 625px) {

}

@media screen and (max-width: 395px) {

}