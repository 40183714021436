.contact-section {
    padding-top: 200px;
    padding-bottom: 200px;
    background-color: var(--spaceblack);
    color: var(--white);
    text-align: left;
}

.contact-title {
    font-size: 110px !important;
    font-weight: bolder;
    text-align: right;
}

.contact-desc {
    font-size: 30px;
    display: flex;
    flex-direction: column; 
    align-items: left; 
    justify-content: center; 
    border-left: 3px solid var(--gray);
    padding-left: 30px;
}

.contact-container {
    display: flex;
    gap: 30px;
}

.contact-container > div {
    flex: 1;
}

@media screen and (max-width: 1200px) {
    .contact-title {
        font-size: 90px !important;
    }
}

@media screen and (max-width: 995px) {
    .contact-section {
        padding: 100px 50px;
    }

    .contact-title {
        font-size: 70px !important;
    }

    .contact-desc {
        font-size: 20px;
    }
}

@media screen and (max-width: 765px) {
    .contact-container {
        flex-direction: column;
    }

    .contact-title {
        text-align: center;
    }

    .contact-desc {
        text-align: center;
        border-left: none;
        padding-left: 0;
    }
}

@media screen and (max-width: 450px) {

    .contact-title {
        font-size: 50px !important;
    }

    .contact-desc {
        font-size: 16px;
    }

    .contact-section {
        padding: 50px 20px;
    }

}