.service-item {
    position: relative;
    padding: 20px;
    background-color: var(--white);
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, border 0.3s ease-in-out;
}

.service-item.focused {
    color: var(--white);
    background-color: rgba(252, 252, 252, 0);
    border: none;
}

.service-item:hover {
    cursor: pointer;
}

.service-name {
    font-weight: 600;
    transition: letter-spacing 0.1s ease-in-out;
}

.service-item:hover .service-name {
    letter-spacing: 0.5px;
    color: var(--orange);
}

.service-number {
    color: var(--gray);
    font-weight: 500;
    transition: color 0.3s ease-in-out;
}

.service-item.focused .service-number {
    color: var(--white);
}

@media screen and (max-width: 990px) {

    .service-item {
        width: 300px;
    }

    .service-name {
        font-size: 20px;
    }

    .service-focus {
        padding: 20px 20px;
    }
}

@media screen and (max-width: 450px) {
    .service-item {
        width: 300px;
    }

    .service-name {
        display: none;
    }

}