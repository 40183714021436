.contactpage-container {
    display: flex;
    align-items: stretch;
    gap: 100px;
    margin-bottom: 50px;
}

.contact-tiles-container {
    display: flex;
    gap: 60px;
    margin-top: 50px;
}

.socials-container {
    display: flex;
    gap: 10px;
}

.contactspage-logo {
    width: 350px;
    height: 350px;
}

@media screen and (max-width: 990px) {
    .contactspage-logo {
        width: 250px;
        height: 250px;
    }

    .contactpage-container {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        gap: 50px;
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 625px) {
    .contactpage-margin {
        margin: 50px;
    }

    .contact-tiles-container {
        flex-direction: column;
    }
}

@media screen and (max-width: 395px) {
    .contactpage-margin {
        margin: 30px;
    }

    .contactpage-container {
        justify-content: center;
    }
}